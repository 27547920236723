
class Order {
  /**
   * @description order list page
   * @param immersed support order-list page immersed
   * @param orderChannel order channel, (ONLINE|OFFLINE), default ONLINE
   * @param tabType default selected tab, (ALL|WAITING_FOR_PAYMENT|PAID|DELIVERED|COMPLETED), default ALL
   * @param location When the value of location is equal to replace, the order-list page will do a replace redirection
   * @param origin page from
   * @param extras extra params, encoded jsonstr
   */
  static OrderList (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/order-list.html?immersed=true&orderChannel={{orderChannel}}&tabType={{tabType}}&location={{location}}&origin={{origin}}&extras={{extras}})&needNavigation=false&topSafeArea=false&bottomSafeArea=false'
      },
      smp: {
        path: '@command_h5path(/order-list.html?orderChannel={{orderChannel}}&tabType={{tabType}}&location={{location}}&origin={{origin}}&extras={{extras}})'
      },
      web: {
        visitPath: '/profile/order?orderChannel={{orderChannel}}&tabType={{tabType}}&location={{location}}&origin={{origin}}&extras={{extras}}',
        localPath: '/order-list.html?orderChannel={{orderChannel}}&tabType={{tabType}}&location={{location}}&origin={{origin}}&extras={{extras}}'
      },
      params: {
        immersed: {
          type: 'optional',
          desc: 'support order-list page immersed'
        },
        orderChannel: {
          type: 'optional',
          desc: 'order channel, (ONLINE|OFFLINE), default ONLINE'
        },
        tabType: {
          type: 'optional',
          desc: 'default selected tab, (ALL|WAITING_FOR_PAYMENT|PAID|DELIVERED|COMPLETED), default ALL'
        },
        location: {
          type: 'optional',
          desc: 'When the value of location is equal to replace, the order-list page will do a replace redirection'
        },
        origin: {
          type: 'optional',
          desc: 'page from'
        },
        extras: {
          type: 'optional',
          desc: 'extra params, encoded jsonstr'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description order detail page
   * @param checkoutId the checkout id
   * @param orderId order hub order number
   * @param ikeaOrderNO Ikea order number
   * @param orderChannel order channel, (ONLINE|OFFLINE), default ONLINE
   * @param orderStatus order status, (WAITING_FOR_PAYMENT|PAID|CANCELED|DELIVERED|COMPLETED)
   * @param immersed support order-list page immersed
   * @param origin page from
   * @param extras extra params, encoded jsonstr
   */
  static OrderDetail (params) {
    const pathObject = {
      app: {
        path: '/default_module/order_detail?checkout_id={{checkoutId}}&orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&immersed={{immersed}}&orderChannel={{orderChannel}}&orderStatus={{orderStatus}}&origin={{origin}}&extras={{extras}}'
      },
      smp: {
        path: '@command_h5path(/order-detail.html?orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&immersed={{immersed}}&orderChannel={{orderChannel}}&orderStatus={{orderStatus}}&origin={{origin}}&extras={{extras}})'
      },
      web: {
        visitPath: '/profile/order-detail?orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&immersed={{immersed}}&orderChannel={{orderChannel}}&orderStatus={{orderStatus}}&origin={{origin}}&extras={{extras}}',
        localPath: '/order-detail.html?orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&immersed={{immersed}}&orderChannel={{orderChannel}}&orderStatus={{orderStatus}}&origin={{origin}}&extras={{extras}}'
      },
      params: {
        checkoutId: {
          type: 'optional',
          desc: 'the checkout id'
        },
        orderId: {
          type: 'optional',
          desc: 'order hub order number'
        },
        ikeaOrderNO: {
          type: 'optional',
          desc: 'Ikea order number'
        },
        orderChannel: {
          type: 'optional',
          desc: 'order channel, (ONLINE|OFFLINE), default ONLINE'
        },
        orderStatus: {
          type: 'optional',
          desc: 'order status, (WAITING_FOR_PAYMENT|PAID|CANCELED|DELIVERED|COMPLETED)'
        },
        immersed: {
          type: 'optional',
          desc: 'support order-list page immersed'
        },
        origin: {
          type: 'optional',
          desc: 'page from'
        },
        extras: {
          type: 'optional',
          desc: 'extra params, encoded jsonstr'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description order detail page in native
   * @param checkoutId the checkout id
   * @param orderId order hub order number
   * @param ikeaOrderNO Ikea order number
   * @param origin page from
   */
  static NativeOrderDetail (params) {
    const pathObject = {
      app: {
        path: '/default_module/order_detail?checkout_id={{checkoutId}}&orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&origin={{origin}}'
      },
      smp: {
        path: '/subpages/checkout/pages/order_detail_nova/order_detail?checkoutId={{checkoutId}}&origin={{origin}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        checkoutId: {
          type: 'optional',
          desc: 'the checkout id'
        },
        orderId: {
          type: 'optional',
          desc: 'order hub order number'
        },
        ikeaOrderNO: {
          type: 'optional',
          desc: 'Ikea order number'
        },
        origin: {
          type: 'optional',
          desc: 'page from'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description the page for searching orders
   * @param id the order id
   */
  static Search (params) {
    const pathObject = {
      app: {
        path: '/order_search?id={{id}}'
      },
      smp: {
        path: 'unsupported'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        id: {
          type: 'optional',
          desc: 'the order id'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description the page for confirming the order
   * @param products the stringfied json of products, e.g. JSON.stringfy([productFullId: xxx, quantity: xxx])
   * @param scene where did you enter to order-confirm page from?
   */
  static Confirm (params) {
    const pathObject = {
      app: {
        path: '/order_confirm?products={{products}}&scene={{scene}}'
      },
      smp: {
        path: '/subpages/checkout/pages/checkout_nova/checkout?productList={{products}}&scene={{scene}}'
      },
      web: {
        visitPath: '/apps/pay/pay?products={{products}}&scene={{scene}}'
      },
      params: {
        products: {
          type: 'required',
          desc: 'the stringfied json of products, e.g. JSON.stringfy([productFullId: xxx, quantity: xxx])'
        },
        scene: {
          type: 'optional',
          desc: 'where did you enter to order-confirm page from?'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description track and trace page
   * @param orderNumber order numner
   * @param type default tab, truck or parcel
   */
  static TrackAndTrace (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/order-logistics.html?orderNo={{orderNumber}}&type={{type}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/order-logistics.html?orderNo={{orderNumber}}&type={{type}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/order-logistics?orderNo={{orderNumber}}&type={{type}}',
        localPath: '/apps/order-logistics?orderNo={{orderNumber}}&type={{type}}'
      },
      params: {
        orderNumber: {
          type: 'required',
          desc: 'order numner'
        },
        type: {
          type: 'optional',
          desc: 'default tab, truck or parcel'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description new track and trace page, support logistics and assembly services
   * @param immersed support app page immersed
   * @param orderId orderId
   * @param ikeaOrderNo ikeaOrderNo
   * @param workOrderNo workOrderNo
   * @param serviceNo serviceNo
   * @param bizType bizType, logistics or assemblyService, default is logistics
   * @param extras extra params
   */
  static TrackAndTraceNext (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/track-and-trace.html?ikeaOrderNo={{ikeaOrderNo}}&orderId={{orderId}}&workOrderNo={{workOrderNo}}&serviceNo={{serviceNo}}&bizType={{bizType}}&immersed={{immersed}}&extras={{extras}})&needNavigation=false&topSafeArea=false&bottomSafeArea=false'
      },
      smp: {
        path: '@command_h5path(/track-and-trace.html?ikeaOrderNo={{ikeaOrderNo}}&orderId={{orderId}}&workOrderNo={{workOrderNo}}&serviceNo={{serviceNo}}&bizType={{bizType}}&immersed={{immersed}}&extras={{extras}})'
      },
      web: {
        visitPath: '/apps/order/track-and-trace?ikeaOrderNo={{ikeaOrderNo}}&orderId={{orderId}}&workOrderNo={{workOrderNo}}&serviceNo={{serviceNo}}&bizType={{bizType}}&immersed={{immersed}}&extras={{extras}}',
        localPath: '/track-and-trace.html?ikeaOrderNo={{ikeaOrderNo}}&orderId={{orderId}}&workOrderNo={{workOrderNo}}&serviceNo={{serviceNo}}&bizType={{bizType}}&immersed={{immersed}}&extras={{extras}}'
      },
      params: {
        immersed: {
          type: 'optional',
          desc: 'support app page immersed'
        },
        orderId: {
          type: 'optional',
          desc: 'orderId'
        },
        ikeaOrderNo: {
          type: 'optional',
          desc: 'ikeaOrderNo'
        },
        workOrderNo: {
          type: 'optional',
          desc: 'workOrderNo'
        },
        serviceNo: {
          type: 'optional',
          desc: 'serviceNo'
        },
        bizType: {
          type: 'optional',
          desc: 'bizType, logistics or assemblyService, default is logistics'
        },
        extras: {
          type: 'optional',
          desc: 'extra params'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description the page for paylink order page
   * @param orderNumber the payment order id
   * @param validationToken the payment order validation token
   */
  static PaylinkOrder (params) {
    const pathObject = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: 'unsupported'
      },
      web: {
        visitPath: '/pay/payorder/?orderNumber={{orderNumber}}&validationToken={{validationToken}}'
      },
      params: {
        orderNumber: {
          type: 'required',
          desc: 'the payment order id'
        },
        validationToken: {
          type: 'required',
          desc: 'the payment order validation token'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description order refund page
   * @param orderId order hub order number
   * @param immersed support app page immersed
   * @param origin page from
   */
  static OrderRefund (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/order-refund.html?orderId={{orderId}}&origin={{origin}})'
      },
      smp: {
        path: '@command_h5path(/order-refund.html?orderId={{orderId}}&origin={{origin}})'
      },
      web: {
        visitPath: '/apps/order/order-refund?orderId={{orderId}}&origin={{origin}}',
        localPath: '/order-refund.html?orderId={{orderId}}&origin={{origin}}'
      },
      params: {
        orderId: {
          type: 'required',
          desc: 'order hub order number'
        },
        immersed: {
          type: 'optional',
          desc: 'support app page immersed'
        },
        origin: {
          type: 'optional',
          desc: 'page from'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description order review page
   * @param immersed support app page immersed
   * @param origin page from
   */
  static OrderReview (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/order-post-view.html?origin={{origin}})'
      },
      smp: {
        path: '@command_h5path(/order-post-view.html?origin={{origin}})'
      },
      web: {
        visitPath: '/apps/order/order-post-view?origin={{origin}}',
        localPath: '/order-post-view.html?origin={{origin}}'
      },
      params: {
        immersed: {
          type: 'optional',
          desc: 'support app page immersed'
        },
        origin: {
          type: 'optional',
          desc: 'page from'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description aftersales detail page
   * @param afterSalesStatus afterSales status (SELF_SERVICE_EXCHANGE|SELF_SERVICE_RETURN)
   * @param afterSalesServiceType after sales service type (CREATED|PROCESSING|COMPLETED)
   * @param orderId order hub order number
   * @param ikeaOrderNO Ikea order number
   * @param immersed support app page immersed
   * @param origin page from
   */
  static AftersalesDetail (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/aftersales-detail.html?immersed=true&orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&afterSalesStatus={{afterSalesStatus}}&afterSalesServiceType={{afterSalesServiceType}})&origin={{origin}}&needNavigation=false&topSafeArea=false&bottomSafeArea=false'
      },
      smp: {
        path: '@command_h5path(/aftersales-detail.html?orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&afterSalesStatus={{afterSalesStatus}}&afterSalesServiceType={{afterSalesServiceType}}&origin={{origin}})'
      },
      web: {
        visitPath: '/apps/order/aftersales-detail?orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&afterSalesStatus={{afterSalesStatus}}&afterSalesServiceType={{afterSalesServiceType}}&origin={{origin}}',
        localPath: '/aftersales-detail.html?orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&afterSalesStatus={{afterSalesStatus}}&afterSalesServiceType={{afterSalesServiceType}}&origin={{origin}}'
      },
      params: {
        afterSalesStatus: {
          type: 'required',
          desc: 'afterSales status (SELF_SERVICE_EXCHANGE|SELF_SERVICE_RETURN)'
        },
        afterSalesServiceType: {
          type: 'required',
          desc: 'after sales service type (CREATED|PROCESSING|COMPLETED)'
        },
        orderId: {
          type: 'optional',
          desc: 'order hub order number'
        },
        ikeaOrderNO: {
          type: 'optional',
          desc: 'Ikea order number'
        },
        immersed: {
          type: 'optional',
          desc: 'support app page immersed'
        },
        origin: {
          type: 'optional',
          desc: 'page from'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description order assembly service
   * @param immersed support app page immersed
   * @param orderId orderId
   * @param orderStatus order status, (WAITING_FOR_PAYMENT|PAID|CANCELED|DELIVERED|COMPLETED)
   * @param ikeaOrderNO ikeaOrderNO
   * @param orderChannel order channel, (ONLINE|OFFLINE), default ONLINE
   */
  static AssemblyService (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/assembly-service.html?ikeaOrderNO={{ikeaOrderNO}}&orderId={{orderId}}&orderStatus={{orderStatus}}&orderChannel={{orderChannel}}&immersed={{immersed}})&needNavigation=false&topSafeArea=false&bottomSafeArea=false'
      },
      smp: {
        path: '@command_h5path(/assembly-service.html?ikeaOrderNO={{ikeaOrderNO}}&orderId={{orderId}}&orderStatus={{orderStatus}}&orderChannel={{orderChannel}}&immersed={{immersed}})'
      },
      web: {
        visitPath: '/apps/order/assembly-service?ikeaOrderNO={{ikeaOrderNO}}&orderId={{orderId}}&orderStatus={{orderStatus}}&orderChannel={{orderChannel}}&immersed={{immersed}}',
        localPath: '/assembly-service.html?ikeaOrderNO={{ikeaOrderNO}}&orderId={{orderId}}&orderStatus={{orderStatus}}&orderChannel={{orderChannel}}&immersed={{immersed}}'
      },
      params: {
        immersed: {
          type: 'optional',
          desc: 'support app page immersed'
        },
        orderId: {
          type: 'optional',
          desc: 'orderId'
        },
        orderStatus: {
          type: 'optional',
          desc: 'order status, (WAITING_FOR_PAYMENT|PAID|CANCELED|DELIVERED|COMPLETED)'
        },
        ikeaOrderNO: {
          type: 'optional',
          desc: 'ikeaOrderNO'
        },
        orderChannel: {
          type: 'optional',
          desc: 'order channel, (ONLINE|OFFLINE), default ONLINE'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
}
export default Order;
