import afterSales from './after-sales'
import assembly_service from './assembly-service'
import cancellation from './cancellation'
import customer_service from './customer-service'
import error from './error'
import order_detail from './order-detail'
import order_list from './order-list'
import order_post_view from './order-post-view'
import order_refund from './order-refund'
import recommendation from './recommendation'
import system from './system'
import track_and_trace from './track-and-trace'

export default {
  value: 'en',
  message: {
    loading: {
      text: 'Loading...'
    },
    system,
    error,
    order_list,
    order_detail,
    order_refund,
    order_post_view,
    cancellation,
    customer_service,
    recommendation,
    afterSales,
    assembly_service,
    track_and_trace,
  }
}
