import afterSales from './after-sales'
import assembly_service from './assembly-service'
import delivery from './delivery'
import discounts_popup from './discounts-popup'
import order from './order'
import orderActions from './orderActions'
import orderStatus from './orderStatus'
import product from './product'

export default {
  ...order,
  title: 'Order Details',
  status: {
    ...orderStatus,
    waiting_for_payment: `${orderStatus.waiting_for_payment}`,
    canceled: `Order ${orderStatus.canceled}`,
    completed: `Order ${orderStatus.completed}`,
  },
  actions: {
    ...orderActions,
    fold: 'Fold',
    unfold: 'Unfold',
  },
  count_down_template: {
    prefix: '',
    suffix: 'Remaining Payment Time',
    format1: 'mm:ss',
    format2: 'HH:mm:ss',
    scan_go_by_csc_store: 'The order will be automatically canceled at 24:00 on the same day. Please make the payment as soon as possible.',
  },
  product,
  delivery,
  assembly_service,
  reload: 'Reload',
  service_down: 'Server exception, please try again later',
  discounts_popup,
  offline: {
    ikeaOrderNo: {
      label: 'Isell Order ID'
    }
  },
  afterSales: {
    ...afterSales
  },
  err: {
    forbidden_to_order: {
      title: 'The order has been paid for.',
      desc: 'This order does not require further payment. Please verify with the user. If you have any questions, please consult the shopping mall staff.'
    },
    forbidden_scango_cancelled_order: {
      title: 'The order has been paid for.',
      desc: 'This order has been paid for and cannot be viewed. If you have any questions, please consult the shopping mall staff.'
    },
    forbidden_scango_paid_order: {
      title: 'The order has been paid for.',
      desc: 'This order has been paid for and cannot be viewed. If you have any questions, please consult the shopping mall staff.'
    }
  },
  feedback: {
    normal: {
      title: 'Please tell us your thoughts about this purchase',
      desc: 'Please provide your valuable feedback. Your feedback will help us to continue improving our service quality',
      btnText: 'Tell us your feedback',
      btnTip: 'Earn 50 membership points'
    },
    after: {
      title: 'How was your shopping experience this time? ',
      desc: 'Your feedback will help us to continuously improve',
      btnText: 'Give feedback'
    }
  },
  receivedPoints: '{receivedPoints} member points have been received',
  locker: {
    lockerNo: 'Locker',
    pickupNo: 'Pick-up code',
    pickupLocation: 'Pick up with the code below at {lockerLocation}.'
  },
  pickupPoint: {
    address: {
      copy: {
        success: 'Store address Copied',
        fail: 'Store address Copy Failure'
      }
    },
    tips: {
      title: "Don't want to pick up the products in person?",
      content: "You can also place an order with other express delivery services and have the products delivered to your home.",
      action: "Learn more"
    }
  },
  partInternalCancel: {
    title: 'The following items have been cancelled, and the amount will be refunded to the original payment account.',
    tag: 'Cancelled'
  },
  asisProduct: {
    title: 'Recycling Market',
    desc1: 'This item from the Recycling market has been sold and details are no longer available.',
    desc2: 'This Recycling market item is one-of-a-kind. If you are looking for similar items, please feel free to browse our Recycling market product page.'
  },
  subsidy_info: {
    first_payment: 'Transaction 1: Government Subsidized Product',
    second_payment: 'Transaction 2: Service Fee',
    payment_amount: 'Amount: ',
    payment_brand: 'Payment Method:',
    payment_status: 'Payment Status:：',
    payment_finished: 'Completed',
    not_paid: 'unpaid',
    paid: 'paid',
    pay_now: 'Pay Now'
  }
}
