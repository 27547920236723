export default {
  bizTabs: {
    logistics: '配送',
    assembly: '组装'
  },
  logistics: {
    page: {
      title: '物流详情',
      empty: '暂无物流信息，请稍后查询',
      reload: '重新加载',
      workOrder: {
        shipment: {
          package: {
            title: '包裹信息',
            shipmentIdLabel: '运单号：',
            carrierNameLabel: '承运方：',
            deliveryTimeLabel: '预计送达：'
          },
          deliveryStaffNameLabel: '配送员',
          deliveryStaffPhoneLabel: '电话联系',
          itemsSheet: {
            title: '商品清单',
            close: '关闭',
            countTemplate: '共{count}件',
            fullCountTemplate: '{transport_method}包裹共{count}件'
          }
        }
      }
    },
    preview: {
      title: '物流动态',
      greenEnergy: '新能源配送',
      viewDetail: '查看详情',
    },
    transportMethods: {
      PARCEL: '快递配送',
      TRUCK: '货车配送',
      default: '普通配送'
    },
    package: {
      prefix: '包裹'
    }
  },
  assembly: {
    page: {
      title: '服务详情',
      empty: '暂无服务信息，请稍后查询',
      reload: '重新加载',
      shipment: {
        service: {
          title: '服务信息',
          shipmentIdLabel: '服务单号：',
          serviceStatusLabel: '服务状态：',
          appointmentTimeLabel: '预计服务时间：'
        },
        setterNameLabel: '组装员',
        setterPhoneLabel: '电话联系',
        itemsSheet: {
          title: '商品清单',
          close: '关闭',
          countTemplate: '共{count}件',
          fullCountTemplate: '共{count}件'
        }
      }
    },
    preview: {
      title: '服务动态',
      viewDetail: '查看详情',
    },
    actions: {
      goToTrackAndTrace: '查看服务动态'
    }
  },
  phoneContactModal: {
    title: '拨打电话',
    content: '欢迎致电：{phoneNumber} 与配送员联系，我们将竭诚为您服务。',
    confirmButtonText: '我知道了',
  },
}
