import orderStatus from './orderStatus'
import orderActions from './orderActions'
import csc from './csc'
import recipient from './recipient'

export default {
  module_name: '订单信息',
  order_no: '订单编号',
  trade_no: '交易编号',
  ikea_order_no: '订单编号：{ikeaOrderNO}',
  apply_time: '下单时间',
  service_order: '客服订单',
  customer_service: '客户服务',
  items_count: '共{count}件',
  has_service: '含服务',
  mobile_order: '手机订单',
  status: {
    ...orderStatus,
  },
  count_down_template: {
    prefix: '订单将于',
    suffix: '后自动取消',
    format1: 'mm 分 ss 秒',
    format2: 'HH 时 mm 分 ss 秒',
    scan_go_by_csc_store: '将于当天24时自动取消，请尽快支付',
  },
  actions: {
    ...orderActions,
  },
  family_no: '会员卡号',
  payment_brand: '支付方式',
  email: '账单邮箱',
  employee_email: '员工邮箱',
  goods_amount: '商品总额',
  delivery_amount: '配送费',
  service_amount: '其他服务费',
  before_subsidy: '补贴前',
  subsidy_goods: '补贴商品',
  delivery_service_amount: '配送及服务费',
  patService: {
    netAmount: '组装服务费',
    bookedServiceTitle: '已订购的宜家组装服务',
    expectedTitle: '预计上门组装时间',
    selectedTitle: '已选择需要组装服务的商品',
    installationFee: '安装费',
    basicFee: '基础服务费',
    moreInfo: '了解收费明细',
    includes: '包含{childItemsCount}件商品',
    status: {
      CREATED: '服务待上门',
      PROCESSED: '服务待上门',
      CANCELED: '服务已取消',
      COMPLETED: '服务已完成',
    }
  },
  coupon_discounts: '优惠券',
  discounts: '活动优惠',
  family_discounts: '会员优惠',
  employee_discounts: '员工优惠',
  total_discounts: '优惠总计',
  prepaid_wallet: {
    label: '宜家卡包抵扣',
    card: '购物卡',
    discount: '抵扣'
  },
  total_amount: '总价',
  real_amount: '实付',
  left_to_pay: '待付金额',
  csc,
  recipient,
  assembly: {
    label: '组装部件',
    header_title: '组装部件',
    title: '商品包含的组装部件',
    actions: {
      back: '返回',
      close: '关闭'
    }
  },
  service: {
    product: '服务商品',
    price: '服务价格',
    estimated_service_time: '预计服务时间',
    actions: {
      fold: '收起',
      unfold: '更多'
    }
  },
  invoice: {
    title: '政府补贴的订单需联系客服申请发票',
    desc: '享受政府补贴的订单仅支持开具“个人”抬头的电子普票。您可直接使用购买记录享受质保。'
  }
}
