import bridge from '@/bridge'
import { CMS_API_ORIGIN } from '@/config/http.config'
import { AfterSalesServiceType_Enums, AfterSalesStatus_Enums } from '@/enums/afterSales'
import { DeliveryOption_Enums } from '@/enums/delivery'
import { OrderChannel_Enums, OrderStatus_Enums } from '@/enums/order'
import { orderUtils } from '@/utils'
import {
  computeOrderStatus, hasServiceInfos, ifShowOfflineOrderPostReview, isCscOrder, isOfflineOrder, isProdOrder, isServiceOrder, isWeb
} from '@/utils/asserts'
import http from '@/utils/http'

import Store from '@/store/index'
import { find, remove } from 'lodash'
import { Origin_Enums } from '@/enums'

console.log('VITE_APP_BUILD_ENV:', import.meta.env.VITE_APP_BUILD_ENV)

const queryOrderList = async(params, isIBAdmin) => {
  console.log('queryOrderList isIBAdmin:', isIBAdmin)

  const isShowOfflineOrderPostReview = await ifShowOfflineOrderPostReview()
  const _isWeb = await isWeb()
  const lang = await bridge.currentLanguage()
  const token = await bridge.getToken()
  console.log('TOKEN:', token)

  // 线下订单『去晒单』功能对客户端版本的限制条件
  if (
    params.data.orderCategory.toUpperCase() === OrderStatus_Enums.COMPLETED.toUpperCase() &&
    params.data.orderChannel.toUpperCase() === OrderChannel_Enums.OFFLINE &&
    !isShowOfflineOrderPostReview
  ) {
    return {
      code: '0',
      data: {
        total: 0,
        data: [],
        currentTimeStamp: null,
      }
    }
  }

  const ret = await http.post(isIBAdmin ? 'ORDER_LIST_4_IB' : 'ORDER_LIST', params, { needCancelLast: true }).then((res) => {
    // res.data.data.data = [];
    // res.data.data.data = res?.data?.data?.data.slice(0, 3);
    (res?.data?.data?.data ?? []).forEach((item) => {
      if (
        item &&
        item.payDueTime - res.data.data.currentTimeStamp <= 0 &&
        item.orderStatus === 'WAITING_FOR_PAYMENT') {
        item.orderStatus = 'CANCELED'
      }
    })
    return res.data
  }).catch(err => {
    // res.data.data.data = []
    if (err?.response?.data?.code) return err.response.data
    throw err
  })

  if (ret.code && ret.code !== '0') return ret;

  const {
    data: {
      data: dataList = []
    } = {}
  } = ret

  if (dataList && dataList.length) {
    dataList.forEach(async (order, index) => {
      if (order) {
        const {
          afterSalesStatus,
          afterSalesServiceType,
          hasAssembleService,
          deliveryOption,
          orderType,
          orderStatus,
          payDueTime,
          isPureServiceOrder,
          paymentTransactionInfos,
        } = order

        const isPaidByWechatLiveStream = !!find(paymentTransactionInfos, { paidByWechatLiveStream: true })

        dataList[index].__asserts__ = {
          lang: lang.value,
          isShowOfflineOrderPostReview,
          isServiceOrder: isServiceOrder({ hasService: hasAssembleService }),
          isSimpleServiceOrder: !!isPureServiceOrder, // 是否纯服务单
          isCscOrder: isCscOrder({
            orderType
          }),
          isWeb: _isWeb,
          orderStatus: computeOrderStatus({
            status: orderStatus,
            deliveryOption,
            remain: payDueTime - ret.data.currentTimeStamp,
          }),
          isPaidByWechatLiveStream,
          hasAfterSalesTip: afterSalesStatus === AfterSalesStatus_Enums.PROCESSING && (afterSalesServiceType !== AfterSalesServiceType_Enums.RPA_CANCEL),
          hasAfterSalesBtn: afterSalesStatus && (afterSalesServiceType !== AfterSalesServiceType_Enums.RPA_CANCEL),
        }
      }
    })
  }
  console.log('orders:', ret);
  return ret
}

const getOrderDetail = async (params) => {
  const _isWeb = await isWeb()
  const lang = await bridge.currentLanguage()

  const isShowOfflineOrderPostReview = await ifShowOfflineOrderPostReview()
  const path = params.validateToken
    ? 'PAYLINK_ORDER_DETAIL'
    : (Store.state.globalState.origin === Origin_Enums.WE_CHAT_WORK ? 'ORDER_DETAIL_WECHAT_WORK' : 'ORDER_DETAIL')

  const prarms = params.validateToken
    ? {
      ikeaOrderNO: params.ikeaOrderNO,
      validateToken: params.validateToken,
    }
    : {
      ikeaOrderNO: params.ikeaOrderNO,
      orderNO: params.orderNO
    }

  prarms.orderChannel = params.orderChannel ?? OrderChannel_Enums.ONLINE
  if (params.isIBAdmin) prarms.isIbAdmin = params.isIBAdmin

  let isWareHouse = false

  const orderDetail = await http.post(path, prarms).then((res) => {
    const resData = res.data || {}

    // 历史原因：货柜自提与仓库自提的deliveryOption都是PUOP，lscType=LSC代表是仓库自提，与门店自提（deliveryOption=CLICK_COLLECT_STORE）一种逻辑
    isWareHouse =
      resData.data.pickupPointInfo &&
      resData.data.pickupPointInfo.lscType === 'LSC'

    if (isWareHouse) {
      resData.data.deliveryOption = DeliveryOption_Enums.CLICK_COLLECT_STORE
    }

    console.log('ORDER DETAIL:', resData)

    return orderUtils.refactOfflineOrderDetail(resData)
  }).catch(async err => {
    if (err?.response?.status === 403 && /^forbidden/.test(err?.response?.data?.code)) {
      return err
    }
    return new Error(JSON.stringify(err))
  })

  if (orderDetail.data) {
    const {
      afterSalesStatus,
      afterSalesServiceType,
      hasAssembleService,
      // deliveryOption,
      orderType,
      deliveryInfos = [],
      serviceInfos,
      orderChannel,
      isPureServiceOrder,
      patService,
      paymentTransactionInfos,
    } = orderDetail.data
  
    // 是否微信视频号订单
    const isPaidByWechatLiveStream = !!find(paymentTransactionInfos, { paidByWechatLiveStream: true })

    orderDetail.data.prepaidWallet = find(paymentTransactionInfos, { paymentBrand: 'PREPAID_WALLET' })

    // orderDetail.data.paymentTransactionInfos = remove(paymentTransactionInfos, function(item) {
    //   return item.paymentBrand !== 'PREPAID_WALLET';
    // });
    // 
    orderDetail.data.__asserts__ = {
      ...(orderDetail.data.__asserts__ ?? {}),
      isOfflineOrder: isOfflineOrder({ orderChannel }),
      isShowOfflineOrderPostReview,
      isServiceOrder: isServiceOrder({ hasService: hasAssembleService }),
      isSimpleServiceOrder: !!isPureServiceOrder, // 是否纯服务单
      isCscOrder: isCscOrder({
        orderType
      }),
      isProdOrder: isProdOrder({
        deliveryInfos
      }),
      hasServiceInfos: hasServiceInfos({
        serviceInfos
      }),
      isWeb: _isWeb,
      isPaylinkOrder: !!params.validateToken,
      lang: lang.value,
      isWareHouse,
      isPaidByWechatLiveStream,
      hasAfterSalesTip: afterSalesStatus === AfterSalesStatus_Enums.PROCESSING && (afterSalesServiceType !== AfterSalesServiceType_Enums.RPA_CANCEL),
      hasAfterSalesBtn: afterSalesStatus && (afterSalesServiceType !== AfterSalesServiceType_Enums.RPA_CANCEL),
    }
  } 

  return orderDetail
}

const getDeliveryContactInfo = async(params) => {
  return await http.get('DELIVERY_CONTACT_INFO', {
    params: {
      orderNO: params.orderNO
    }
  }).then((res) => {
    return res.data
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

const cancelProposal = async(params) => {
  return await http.post('CANCEL_PROPOSAL', {
    orderNO: params.orderNO
  }).then((res) => {
    return res.data
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

const getOrderRefundDetail = async (params) => {
  return await http.get('ORDER_REFUND_DETAIL_V2', { params }).then((res) => {
    return res.data
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

// 待晒单页面的list数据
const getPostViewList = async(params) => {
  const isShowOfflineOrderPostReview = await ifShowOfflineOrderPostReview()
  const _isWeb = await isWeb()
  const lang = await bridge.currentLanguage()
  const ret = await http.post('POST_VIEW_LIST', params).then((res) => {
    return res.data
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
  const {
    data: {
      data: dataList = []
    } = {}
  } = ret

  if (dataList && dataList.length) {
    dataList.forEach(async (order, index) => {
      if (order) {
        const {
          hasAssembleService,
          deliveryOption,
          orderType,
          orderStatus,
          payDueTime,
          isPureServiceOrder,
        } = order

        dataList[index].__asserts__ = {
          lang: lang.value,
          isShowOfflineOrderPostReview,
          isServiceOrder: isServiceOrder({ hasService: hasAssembleService }),
          isSimpleServiceOrder: !!isPureServiceOrder, // 是否纯服务单
          isCscOrder: isCscOrder({
            orderType
          }),
          isWeb: _isWeb,
          orderStatus: computeOrderStatus({
            status: orderStatus,
            deliveryOption,
            remain: payDueTime - ret.data.currentTimeStamp,
          })
        }
      }
    })
  }

  return ret
}

const getRecommendationData = async(params) => {
  return await http.post('FETCH_SCENE_PRODUCTS', params).then((res) => {
    return res.data
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

const addProductsToCart = async(params) => {
  return await http.post('ADD_TO_CART', params).then((res) => {
    return res.data
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

function ordersService() {
  return {
    queryOrderList,
    getOrderDetail,
    cancelProposal,
    getDeliveryContactInfo,
    getOrderRefundDetail,
    getPostViewList,
    getRecommendationData,
    addProductsToCart
  }
}

export default ordersService()
