
class Content {
  /**
   * @description product information page
   * @param id the product's id
   * @param type type for the product
   * @param seriesId seriesId for the product
   * @param tiktok_cid TikTok advertising id
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  static Pip (params) {
    const pathObject = {
      app: {
        path: '/default_module/product?id={{id}}&type={{type}}&seriesId={{seriesId}}',
        version: '>=1.0.0'
      },
      smp: {
        path: '/pages/pip/pip?artNum={{id}}&artType={{type}}&seriesId={{seriesId}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}&tiktok_cid={{tiktok_cid}}',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/p/{{id}}?artType={{type}}'
      },
      params: {
        id: {
          type: 'required',
          desc: 'the product\'s id'
        },
        type: {
          type: 'required',
          desc: 'type for the product'
        },
        seriesId: {
          type: 'optional',
          desc: 'seriesId for the product'
        },
        tiktok_cid: {
          type: 'optional',
          desc: 'TikTok advertising id'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description product list page
   * @param catalogId catalog id
   * @param tagId tag id
   * @param name name
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   */
  static Plp (params) {
    const pathObject = {
      app: {
        path: '/default_module/plp?id={{catalogId}}&name={{name}}&tagId={{tagId}}'
      },
      smp: {
        path: '/pages/plp/plp?catalogId={{catalogId}}&tagId={{tagId}}&name={{name}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}'
      },
      web: {
        visitPath: '/cat/{{catalogId}}'
      },
      params: {
        catalogId: {
          type: 'required',
          desc: 'catalog id'
        },
        tagId: {
          type: 'optional',
          desc: 'tag id'
        },
        name: {
          type: 'optional',
          desc: 'name'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description catalog home page
   */
  static Catalog () {
    const pathObject = {
      app: {
        path: '/category_main'
      },
      smp: {
        path: '/pages/catalog/catalog',
        isTab: true
      },
      web: {
        visitPath: '/guide/category'
      }
    };
    return pathObject;
  }
  /**
   * @description discovery index page
   */
  static Discovery () {
    const pathObject = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: 'unsupported'
      },
      web: {
        visitPath: '/more/more'
      }
    };
    return pathObject;
  }
  /**
   * @description the page for posting reviews
   * @param orderId the order's id
   * @param orderType order type: ONLINE and OFFLINE
   */
  static PostReview (params) {
    const pathObject = {
      app: {
        path: '/default_module/comment_publish?orderId={{orderId}}&orderType={{orderType}}'
      },
      smp: {
        path: '@command_h5path(/flutter-web/index.html#/comment_publish?orderId={{orderId}}&orderType={{orderType}})'
      },
      web: {
        visitPath: '/profile/reviews?orderId={{orderId}}&orderType={{orderType}}'
      },
      params: {
        orderId: {
          type: 'required',
          desc: 'the order\'s id'
        },
        orderType: {
          type: 'optional',
          desc: 'order type: ONLINE and OFFLINE'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description all reviews under an order
   * @param orderId the order's id
   * @param orderType order type: ONLINE or OFFLINE
   * @param reviewId the review's id
   */
  static OrderReviews (params) {
    const pathObject = {
      app: {
        path: '/default_module/comment_order_page?orderId={{orderId}}&orderType={{orderType}}&reviewId={{reviewId}}'
      },
      smp: {
        path: '@command_h5path(/flutter-web/index.html#/comment_order_page?orderId={{orderId}}&orderType={{orderType}}&reviewId={{reviewId}})'
      },
      web: {
        visitPath: '/profile/order/reviews?orderId={{orderId}}&orderType={{orderType}}&reviewId={{reviewId}}'
      },
      params: {
        orderId: {
          type: 'required',
          desc: 'the order\'s id'
        },
        orderType: {
          type: 'optional',
          desc: 'order type: ONLINE or OFFLINE'
        },
        reviewId: {
          type: 'optional',
          desc: 'the review\'s id'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description cms landing page configured in publicera
   * @param id id for cms landing page
   */
  static PubliceraLandingPage (params) {
    const pathObject = {
      app: {
        path: '/cms/page?id={{id}}',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/page/cms/page.html?id={{id}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/modules/page/cms/page.html?id={{id}}',
        path: '/page/cms/page.html?id={{id}}'
      },
      params: {
        id: {
          type: 'required',
          desc: 'id for cms landing page'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description landing page configured in old cms: https://cms.app.ikea.cn
   * @param id id for cms landing page
   */
  static CmsLandingPage (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/page/landing_page.html?id={{id}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/page/landing_page.html?id={{id}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/modules/page/landing_page.html?id={{id}}',
        path: '/page/landing_page.html?id={{id}}'
      },
      params: {
        id: {
          type: 'required',
          desc: 'id for cms landing page'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description product promotion list including activities, coupons, primecard, installment, etc in pip page
   * @param productFullId product id
   * @param productName product name
   */
  static ProductPromotion (params) {
    const pathObject = {
      app: {
        path: '/default_module/webpage_popup?path=@command_encode(/modules/ikeacn-portal/universal/product-promotions.html?productFullId={{productFullId}}&productName={{productName}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/product-promotions.html?productFullId={{productFullId}}&productName={{productName}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/product-promotions?productFullId={{productFullId}}&productName={{productName}}',
        localPath: '/product-promotion.html?productFullId={{productFullId}}'
      },
      params: {
        productFullId: {
          type: 'required',
          desc: 'product id'
        },
        productName: {
          type: 'optional',
          desc: 'product name'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description products that can use the discount or coupon
   * @param discountId discount id
   * @param couponCode coupon code
   * @param pageType page type
   * @param productIds 购物袋以选择商品，以逗号链接的productFullId， 如：productIds=s1234556,123456i
   * @param total 购物袋选择的商品中，所有使用与改活动的商品数量
   * @param activityId 活动 ID，只在包邮活动中使用
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  static CouponProductList (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/coupon-product-list.html?discountId={{discountId}}&activityId={{activityId}}&couponCode={{couponCode}}&pageType={{pageType}}&productIds={{productIds}}&total={{total}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/coupon-product-list.html?discountId={{discountId}}&activityId={{activityId}}&couponCode={{couponCode}}&pageType={{pageType}}&productIds={{productIds}}&total={{total}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/coupon-product-list?discountId={{discountId}}&activityId={{activityId}}&couponCode={{couponCode}}&pageType={{pageType}}&productIds={{productIds}}&total={{total}}',
        path: '/apps/coupon-product-list?discountId={{discountId}}&activityId={{activityId}}&couponCode={{couponCode}}&pageType={{pageType}}&productIds={{productIds}}&total={{total}}'
      },
      params: {
        discountId: {
          type: 'optional',
          desc: 'discount id'
        },
        couponCode: {
          type: 'optional',
          desc: 'coupon code'
        },
        pageType: {
          type: 'optional',
          desc: 'page type'
        },
        productIds: {
          type: 'optional',
          desc: '购物袋以选择商品，以逗号链接的productFullId， 如：productIds=s1234556,123456i'
        },
        total: {
          type: 'optional',
          desc: '购物袋选择的商品中，所有使用与改活动的商品数量'
        },
        activityId: {
          type: 'optional',
          desc: '活动 ID，只在包邮活动中使用'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description all rankings
   * @param roomId room id
   * @param rankingType rankingType
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   */
  static AllRanking (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/room-ranking.html?rankId={{roomId}}&rankingType={{rankingType}})&needNavigation=false&topSafeArea=false',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/room-ranking.html?rankId={{roomId}}&rankingType={{rankingType}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/room-ranking?rankId={{roomId}}&rankingType={{rankingType}}'
      },
      params: {
        roomId: {
          type: 'optional',
          desc: 'room id'
        },
        rankingType: {
          type: 'optional',
          desc: 'rankingType'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description ranking list
   * @param rankId rank id
   * @param rankingType rankingType
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   */
  static RankingList (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/ranking.html?rankId={{rankId}}&rankingType={{rankingType}})&needNavigation=false&topSafeArea=false',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/ranking.html?rankId={{rankId}}&rankingType={{rankingType}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/ranking?rankId={{rankId}}&rankingType={{rankingType}}'
      },
      params: {
        rankId: {
          type: 'required',
          desc: 'rank id'
        },
        rankingType: {
          type: 'optional',
          desc: 'rankingType'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description show product list that can buy together
   * @param variationName variation name
   */
  static AddonProductList (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/content/universal/addon-product-list.html?variationName={{variationName}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/content/universal/addon-product-list.html?variationName={{variationName}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/extension/addon-product-list?variationName={{variationName}}',
        localPath: '/addon-product-list.html?variationName={{variationName}}'
      },
      params: {
        variationName: {
          type: 'optional',
          desc: 'variation name'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description display qrcode
   * @param code code
   */
  static Qrcode (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/content/universal/qrcode-display.html?code={{code}})',
        version: '>=2.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/content/universal/qrcode-display.html?code={{code}})'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        code: {
          type: 'required',
          desc: 'code'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description PIP for Virtual Product
   * @param id id
   */
  static VirtualProductDetail (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/virtual-product-detail.html?id={{id}}&type=VTP)',
        version: '>=2.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/virtual-product-detail.html?id={{id}}&type=VTP)'
      },
      web: {
        visitPath: '/apps/virtual-product-detail?id={{id}}&type=VTP'
      },
      params: {
        id: {
          type: 'required',
          desc: 'id'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description footprints page
   */
  static Footprints () {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/content/universal/footprints.html)',
        version: '>=2.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/content/universal/footprints.html)'
      },
      web: {
        visitPath: 'unsupported'
      }
    };
    return pathObject;
  }
  /**
   * @description theme activity page
   * @param id activity id
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   */
  static ThemeActivity (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/ins-activity.html?id={{id}})&needNavigation=false&topSafeArea=false',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/ins-activity.html?id={{id}}&utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/ins-activity?id={{id}}'
      },
      params: {
        id: {
          type: 'required',
          desc: 'activity id'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description add event to Calendar
   * @param title the event title
   * @param remark type for the product
   * @param timeFrom seriesId for the product
   * @param timeTo seriesId for the product
   * @param jumpLink seriesId for the product
   */
  static AddEventCalendar (params) {
    const pathObject = {
      app: {
        path: '/default_module/addEventToCalendar?title=@command_encode({{title}})&remark=@command_encode({{remark}})&timeFrom=@command_encode({{timeFrom}})&timeTo=@command_encode({{timeTo}})&jumpLink=@command_encode({{jumpLink}})',
        version: '>=1.0.0'
      },
      smp: {
        path: '/subpages/others/pages/subscribe_message/subscribe_message?title=@command_encode({{title}})&remark=@command_encode({{remark}})&timeFrom=@command_encode({{timeFrom}})&timeTo=@command_encode({{timeTo}})&jumpLink=@command_encode({{jumpLink}})',
        version: '>=1.0.0'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        title: {
          type: 'required',
          desc: 'the event title'
        },
        remark: {
          type: 'required',
          desc: 'type for the product'
        },
        timeFrom: {
          type: 'required',
          desc: 'seriesId for the product'
        },
        timeTo: {
          type: 'required',
          desc: 'seriesId for the product'
        },
        jumpLink: {
          type: 'required',
          desc: 'seriesId for the product'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description add event to Calendar
   * @param title the event title
   * @param remark type for the product
   * @param timeFrom seriesId for the product
   * @param timeTo seriesId for the product
   * @param jumpLink seriesId for the product
   */
  static CheckEventStatus (params) {
    const pathObject = {
      app: {
        path: '/default_module/checkEventStatus?title=@command_encode({{title}})&remark=@command_encode({{remark}})&timeFrom=@command_encode({{timeFrom}})&timeTo=@command_encode({{timeTo}})&jumpLink=@command_encode({{jumpLink}})',
        version: '>=1.0.0'
      },
      smp: {
        path: 'unsupported'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        title: {
          type: 'required',
          desc: 'the event title'
        },
        remark: {
          type: 'required',
          desc: 'type for the product'
        },
        timeFrom: {
          type: 'required',
          desc: 'seriesId for the product'
        },
        timeTo: {
          type: 'required',
          desc: 'seriesId for the product'
        },
        jumpLink: {
          type: 'required',
          desc: 'seriesId for the product'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description add event to Calendar
   * @param title the event title
   * @param remark type for the product
   * @param timeFrom seriesId for the product
   * @param timeTo seriesId for the product
   * @param jumpLink seriesId for the product
   */
  static DeleteCalendarEvent (params) {
    const pathObject = {
      app: {
        path: '/default_module/deleteCalendarEvent?title=@command_encode({{title}})&remark=@command_encode({{remark}})&timeFrom=@command_encode({{timeFrom}})&timeTo=@command_encode({{timeTo}})&jumpLink=@command_encode({{jumpLink}})',
        version: '>=1.0.0'
      },
      smp: {
        path: 'unsupported'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        title: {
          type: 'required',
          desc: 'the event title'
        },
        remark: {
          type: 'required',
          desc: 'type for the product'
        },
        timeFrom: {
          type: 'required',
          desc: 'seriesId for the product'
        },
        timeTo: {
          type: 'required',
          desc: 'seriesId for the product'
        },
        jumpLink: {
          type: 'required',
          desc: 'seriesId for the product'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description Recommended product page after placing an order
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   * @param c cch parameter
   */
  static RecommendationOrder (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/recommendation-order.html?utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}})&c={{c}})',
        version: '>=2.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/recommendation-order.html?utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}&c={{c}})'
      },
      web: {
        visitPath: '/apps/recommendation-order.html?utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}&c={{c}}'
      },
      params: {
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        },
        c: {
          type: 'optional',
          desc: 'cch parameter'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description Recommended substitute product page
   * @param productIds productIds
   * @param address address
   * @param zipCode zipCode
   * @param parcelOrNot parcelOrNot
   */
  static RecommendationSubstitute (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/recommendation-substitute.html?productIds={{productIds}}&address={{address}})&parcelOrNot={{parcelOrNot}}&zipCode={{zipCode}}',
        version: '>=2.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/recommendation-substitute.html?productIds={{productIds}}&address={{address}})&parcelOrNot={{parcelOrNot}}&zipCode={{zipCode}}'
      },
      web: {
        visitPath: '/apps/recommendation/substitute?productIds={{productIds}}&address={{address}}&parcelOrNot={{parcelOrNot}}&zipCode={{zipCode}}'
      },
      params: {
        productIds: {
          type: 'required',
          desc: 'productIds'
        },
        address: {
          type: 'optional',
          desc: 'address'
        },
        zipCode: {
          type: 'optional',
          desc: 'zipCode'
        },
        parcelOrNot: {
          type: 'optional',
          desc: 'parcelOrNot'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description Recommended related product page
   * @param productIds productIds,支持多个id,用英文,隔开
   */
  static RecommendationRelated (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/recommendation-related.html?productIds={{productIds}})',
        version: '>=2.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/recommendation-related.html?productIds={{productIds}})'
      },
      web: {
        visitPath: '/apps/recommendation/related?productIds={{productIds}}'
      },
      params: {
        productIds: {
          type: 'required',
          desc: 'productIds,支持多个id,用英文,隔开'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description shared wishlist page
   * @param tiktok_cid TikTok advertising id
   * @param utm_source utm_source
   * @param utm_medium utm_medium
   * @param utm_campaign utm_campaign
   * @param utm_content utm_content
   * @param utm_term utm_term
   */
  static SharedWishlist (params) {
    const pathObject = {
      app: {
        path: 'unsupported'
      },
      smp: {
        path: '/pages/shared_wishlist/shared_wishlist?utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}&tiktok_cid={{tiktok_cid}}'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        tiktok_cid: {
          type: 'optional',
          desc: 'TikTok advertising id'
        },
        utm_source: {
          type: 'optional',
          desc: 'utm_source'
        },
        utm_medium: {
          type: 'optional',
          desc: 'utm_medium'
        },
        utm_campaign: {
          type: 'optional',
          desc: 'utm_campaign'
        },
        utm_content: {
          type: 'optional',
          desc: 'utm_content'
        },
        utm_term: {
          type: 'optional',
          desc: 'utm_term'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description offline store acitvities page
   * @param storeId storeId
   */
  static OfflineStoreActivity (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/store-activity.html?storeId={{storeId}})',
        version: '>=2.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/store-activity.html?storeId={{storeId}})'
      },
      web: {
        visitPath: 'unsupported'
      },
      params: {
        storeId: {
          type: 'required',
          desc: 'storeId'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
  /**
   * @description redeem products
   * @param totalPrice 购物袋合计金额
   */
  static RedeemPage (params) {
    const pathObject = {
      app: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/redeem.html?totalPrice={{totalPrice}}&silent=true)',
        version: '>=1.0.0'
      },
      smp: {
        path: '@command_h5path(/modules/ikeacn-portal/universal/redeem.html?totalPrice={{totalPrice}}&silent=true)',
        version: '>=1.0.0'
      },
      web: {
        visitPath: '/apps/redeem?totalPrice={{totalPrice}}&silent=true'
      },
      params: {
        totalPrice: {
          type: 'optional',
          desc: '购物袋合计金额'
        }
      }
    };
    pathObject.receivedParams = params;
    return pathObject;
  }
}
export default Content;
